<template>
  <ul>
    <div class="row bg-noti mt-1 mb-1">

      <div class="col">
        <router-link
          :to="{ name: '/' }"
          style="color: #b4b7bd; font-size: 12px"
        >
          <b-badge
            rounded
            class="ml-auto"
          >
            0
          </b-badge>
          Notify
        </router-link>
      </div>
      <div class="col">
        <router-link
          :to="{ name: '/' }"
          style="color: #b4b7bd; font-size: 12px"
        >
          <b-badge
            rounded
            :variant="alertwit_check ? 'danger' : 'secondary'"
            class="ml-auto"
            :class="alertwit_check ? 'blob red' : ''"
          >
            {{ alertwit.length ? alertwit.length : 0 }}
          </b-badge>
          Setting
        </router-link>
      </div>
    </div>
    <!-- <div class="pl-1 pr-1 border-primary rounded mr-1 ml-1 mb-1 bg-balance">
      <div class="d-flex justify-content-between">
        <p>บัญชีฝาก SCB 1</p>
        <p>{{ Commas(deposit_balance) }} บาท</p>
      </div>
      <div
        v-if="UserData.agent_id === 1 || UserData.agent_id === 2 || UserData.agent_id === 4"
        class="d-flex justify-content-between"
      >
        <p>บัญชีฝาก SCB 2</p>
        <p>{{ Commas(deposit2_balance) }} บาท</p>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีฝาก KBANK</p>
        <p>{{ Commas(deposit_kbank_balance) }} บาท</p>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีถอน KBANK</p>
        <p>{{ Commas(withdraw_kbank_balance) }} บาท</p>
      </div>
      <div class="d-flex justify-content-between">
        <p>บัญชีถอน SCB</p>
        <p>{{ Commas(withdraw_scb_balance) }} บาท</p>
      </div>
    </div> -->
    <b-toast
      id="example-toast"
      no-auto-hide
      header-class="warning"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <strong
            class="mr-auto"
          ><i class="fas fa-envelope" /> แจ้งฝากเงิน</strong>
          <small class="text-muted">11 mins ago</small>
        </div>
      </template>
      <span>Hello, world! This is a toast message. Hope you're doing well..
        :)</span>
    </b-toast>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import { BBadge, BToast } from 'bootstrap-vue'
// eslint-disable-next-line import/no-unresolved
import TimeAgo from 'javascript-time-ago'
// eslint-disable-next-line import/extensions
import en from 'javascript-time-ago/locale/en'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

const data = { soundurl: '/alarm/siren-alert.mp3' }

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    BBadge,
    BToast,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      alertwit: null,
      alertwit_check: 0,
      withdraw_balance: 0,
      deposit_balance: 0,
      deposit2_balance: 0,
      withdraw_kbank_balance: 0,
      withdraw_scb_balance: 0,
      deposit_kbank_balance: 0,
      agent: null,
    }
  },
  destroyed() {
    clearInterval(this.interval)
  },
  async created() {
    // this.interval = setInterval(() => {
    //   if (this.UserData.role !== 'zean') {
    //     // console.log('getWithdraw')
    //     this.getWithdraw()
    //     if (localStorage.getItem('alertwit')) {
    //       if (JSON.parse(localStorage.getItem('alertwit')).length > 0 && this.agent.alarm === 1) {
    //         if (this.alertwit.length === JSON.parse(localStorage.getItem('alertwit')).length) {
    //           this.alertwit = JSON.parse(localStorage.getItem('alertwit'))
    //         } else {
    //           this.$bvToast.hide('aletrwit')
    //           this.alertwit = JSON.parse(localStorage.getItem('alertwit'))
    //           // this.AlertWit()
    //         }
    //         this.alertwit_check = 1
    //         const audio = new Audio(data.soundurl)
    //         audio.play()
    //       } else if (JSON.parse(localStorage.getItem('alertwit')).length > 0) {
    //         this.alertwit = JSON.parse(localStorage.getItem('alertwit'))
    //         this.alertwit_check = 1
    //       } else {
    //         this.alertwit = []
    //         this.alertwit_check = null
    //       }
    //     } else {
    //       this.alertwit = []
    //       this.alertwit_check = null
    //     }
    //     this.GetData()
    //   }
    //   // this.AlertWit()
    // }, 30000)
  },
  async mounted() {
    if (this.UserData.role !== 'zean') {
      // await this.getWithdraw()
      if (localStorage.getItem('alertwit')) {
        if (JSON.parse(localStorage.getItem('alertwit')).length > 0) {
          this.alertwit = JSON.parse(localStorage.getItem('alertwit'))
          this.alertwit_check = 1
        } else {
          this.alertwit = []
          this.alertwit_check = null
        }
        // this.AlertWit()
      } else {
        this.alertwit = []
        this.alertwit_check = null
      }
      // this.GetData()
      // this.getAgent()
    }
  },
  methods: {
    getAgent() {
      this.$http
        .get('/agent/show')
        .then(response => {
          this.agent = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    GetData() {
      this.$http
        .get('/agent/GetMenuBalance')
        .then(response => {
          this.withdraw_balance = response.data.withdraw_balance
          this.deposit_balance = response.data.deposit_balance
          this.deposit2_balance = response.data.deposit2_balance ? response.data.deposit2_balance : 0
          this.withdraw_kbank_balance = response.data.withdraw_kbank_balance
          this.withdraw_scb_balance = response.data.withdraw_scb_balance
          this.deposit_kbank_balance = response.data.deposit_kbank_balance
        })
        .catch(error => console.log(error))
    },
    getWithdraw() {
      // let index = 0
      const arry = []
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
      }
      this.$http
        .get('/withdraw', { params })
        .then(response => {
          this.show = false
          this.depositdata = response.data.data
          // console.log(response.data)
          this.depositdata.forEach(items => {
            if (items.status === 'waiting_approve') {
              arry.push(items)
            }
          })
          localStorage.setItem('alertwit', JSON.stringify(arry))
        })
        .catch(error => console.log(error))
    },
    AlertWit() {
      if (this.alertwit.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.alertwit.length; i++) {
          this.makeToast(this.alertwit[i])
        }
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    makeToast(text) {
      // console.log(new Date(text.created_at))
      TimeAgo.addDefaultLocale(en)
      const timeAgo = new TimeAgo('en-US')
      const time = timeAgo.format(new Date(text.created_at))
      console.log(time)
      this.deposit = true
      const audio = new Audio(data.soundurl)
      audio.play()

      const h = this.$createElement
      const vNodesTitle = h(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
        [
          h('strong', { class: 'mr-2' }, '💸 แจ้งถอนเงิน'),
          h('small', { class: 'ml-auto font-italics' }, time),
        ],
      )

      this.$bvToast.toast(`username : ${text.username} ถอนเงิน ${text.amount} บาท`, {
        id: 'aletrwit',
        title: [vNodesTitle],
        noAutoHide: true,
        variant: 'secondary',
        toaster: 'b-toaster-bottom-right',
        solid: true,
      })
    },
  },
}
</script>

<style scoped>
  .bg-balance {
    background-color: #151c23;
    box-shadow: 0 0 0 5px rgba(227, 85, 255, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  }
.transaction-tab {
  flex-wrap: nowrap;
  overflow: hidden;
}
.bg-noti {
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  background-color: #151c23;
  /* border-top: #8b55a3 solid 1px;
  border-bottom: #8b55a3 solid 1px;
  box-shadow: 0 0 0 5px rgba(227, 85, 255, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1); */
  padding: 9px 5px;
  height: 40px;
}
#flix-tool a {
  color: #eee;
}
.blob {
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 1s infinite;
}
.blob.red {
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 0.5s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
div p {
  margin: 0;
  font-size: 12px;
}
</style>
